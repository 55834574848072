
import {
  defineComponent,
  onMounted,
  toRefs,
  ref,
  reactive,
  ComputedRef,
  computed,
  watch,
  toRaw,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  ExportSurveyValidityStudyRequestDto,
  ExportToExcelResponseDto,
} from "../../core/types/ExcelTypes";
import * as Yup from "yup";
import {
  SurveyValidityRequestDto,
  SurveyValidityLineItemDto,
} from "../../core/types/AnalysisTypes";

interface DropDownSetup {
  selectedClient: number;
  selectedClientLocation: number;
}

export default defineComponent({
  name: "survey-validity",
  components: { Field, Form, ErrorMessage },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const initialDropDownSetup: DropDownSetup = {
      selectedClient: 0,
      selectedClientLocation: 0,
    };
    const runReportButton = ref<HTMLElement | null>(null);
    const exportToExcelButton = ref<HTMLButtonElement | null>(null);
    const dropDownSetup = reactive<DropDownSetup>({ ...initialDropDownSetup });
    const areResultsReady = ref(false);
    const isExcelFileReady = ref(false);
    const surveyValidityRequestDto = ref<SurveyValidityRequestDto>({
      clientId: 0,
      locationId: 0,
    });
    const searchSurveys = ref("");
    const validateForm = Yup.object().shape({
      clientLocationField: Yup.number()
        .min(10, "Location is required")
        .required()
        .label("Client Location"),
    });

    const exportSurveyValidityStudyRequestDto =
      ref<ExportSurveyValidityStudyRequestDto>({
        clientId: 0,
        locationId: 0,
        surveys: [],
      });

    onMounted(() => {
      setCurrentPageTitle("Survey Analysis");
      setCurrentPageBreadcrumbs("Survey Validity Report", ["Survey Analysis"]);
      getClients();
    });

    const getClients = () => {
      resetResultAreReady();

      store
        .dispatch(Actions.GET_CLIENTS_DDL, 0)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const clientList = computed(() => {
      return store.getters.getClientDDL;
    });

    const getClientLocations = () => {
      dropDownSetup.selectedClientLocation = 0;
      store
        .dispatch(
          Actions.GET_CLIENT_LOCATIONS_DDL,
          toRaw(dropDownSetup.selectedClient)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving client locations.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const clientLocationList = computed(() => {
      return store.getters.getClientLocationsDDL;
    });

    const surveyValidityResults = computed(() => {
      return store.getters.surveyAnalysisValidityResults;
    });

    const onSubmitRunReport = () => {
      // Activate indicator
      runReportButton.value?.setAttribute("data-kt-indicator", "on");

      isExcelFileReady.value = false;

      surveyValidityRequestDto.value.clientId = dropDownSetup.selectedClient;
      surveyValidityRequestDto.value.locationId =
        dropDownSetup.selectedClientLocation;

      store
        .dispatch(Actions.POST_SURVEY_VALIDITY, surveyValidityRequestDto.value)
        .then(() => {
          //
          runReportButton.value?.removeAttribute("data-kt-indicator");
          areResultsReady.value = true;
        })
        .catch(() => {
          runReportButton.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving survey validity",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const filterSurveys: ComputedRef<Array<SurveyValidityLineItemDto>> =
      computed(() => {
        return surveyValidityResults.value.filter((survey) => {
          return (
            survey.respondentLastName
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1
          );
        });
      });

    const excelFileResponse: ComputedRef<ExportToExcelResponseDto> = computed(
      () => {
        return store.getters.getExcelFile;
      }
    );

    watch(excelFileResponse, () => {
      if (excelFileResponse.value.excelFilename !== "na") {
        isExcelFileReady.value = true;

        //Deactivate indicator
        exportToExcelButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = false;
      } else if (excelFileResponse.value.excelFilename === "na") {
        isExcelFileReady.value = false;
      }
    });

    function resetResultAreReady() {
      areResultsReady.value = false;
    }

    function exportToExcel() {
      exportSurveyValidityStudyRequestDto.value.clientId =
        dropDownSetup.selectedClient;
      exportSurveyValidityStudyRequestDto.value.locationId =
        dropDownSetup.selectedClientLocation;
      exportSurveyValidityStudyRequestDto.value.surveys =
        surveyValidityResults.value;

      store.commit(Mutations.PURGE_EXCEL_FILE);

      store
        .dispatch(
          Actions.EXPORT_SURVEY_VALIDITY_STUDY_TO_EXCEL,
          exportSurveyValidityStudyRequestDto.value
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem with sync",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (exportToExcelButton.value) {
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = true;
        // Activate indicator
        exportToExcelButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    return {
      currentUser,
      validateForm,
      exportSurveyValidityStudyRequestDto,
      runReportButton,
      exportToExcelButton,
      ...toRefs(dropDownSetup),
      areResultsReady,
      isExcelFileReady,
      surveyValidityRequestDto,
      clientList,
      filterSurveys,
      searchSurveys,
      getClientLocations,
      clientLocationList,
      surveyValidityResults,
      onSubmitRunReport,
      excelFileResponse,
      exportToExcel,
    };
  },
});
