<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body px-0 pt-3 pb-0">
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Form-->
        <Form
          class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          novalidate="novalidate"
          @submit="onSubmitRunReport"
          id="matchJob_to_behavioralProfile_form"
          :validation-schema="validateForm"
        >
          <div class="card-header cursor-pointer flex-column">
            <!--begin::Card title-->
            <div class="card-title d-flex flex-column mb-3">
              <h3 class="fw-bolder m-0">Survey Validity Report</h3>
            </div>
            <!--end::Card title-->
            <div
              class="d-flex flex-column"
              v-if="currentUser.role === 'SuperAdmin'"
            >
              <div class="d-flex flex-row flex-wrap">
                <div
                  class="
                    d-flex
                    flex-column flex-fill
                    incline-primary-blue-light-bkgrd incline-border-radius
                    p-4
                    mb-3
                    w-sm-25
                  "
                >
                  <h5 class="incline-primary-dark-blue">Client</h5>
                  <!--begin Select Client Dropdown-->
                  <div>
                    <Field
                      name="client"
                      class="form-select form-select-solid mb-2"
                      as="select"
                      v-model="selectedClient"
                      @change="getClientLocations()"
                      label="client"
                    >
                      <option value="0">Select Client...</option>
                      <option
                        v-for="item in clientList"
                        :key="item.clientId"
                        :label="item.name"
                        :value="item.clientId"
                      >
                        {{ item.name }}
                      </option>
                    </Field>
                  </div>
                  <!--end:: Select Client Dropdown-->
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column"
              v-if="currentUser.role === 'SuperAdmin'"
            >
              <div class="d-flex flex-row flex-wrap">
                <div
                  class="
                    d-flex
                    flex-column flex-fill
                    incline-primary-blue-light-bkgrd incline-border-radius
                    p-4
                    mb-3
                    w-sm-25
                  "
                >
                  <h5 class="incline-primary-dark-blue">Location</h5>
                  <!--begin Select Client Dropdown-->
                  <div>
                    <Field
                      name="clientLocationField"
                      class="form-select form-select-solid mb-2"
                      as="select"
                      v-model="selectedClientLocation"
                    >
                      <option value="0">Select Location...</option>
                      <option
                        v-for="item in clientLocationList"
                        :key="item.clientLocationId"
                        :label="item.locationName"
                        :value="item.clientLocationId"
                      >
                        {{ item.locationName }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="clientLocationField" />
                      </div>
                    </div>
                  </div>
                  <!--end:: Select Client Dropdown-->
                </div>
              </div>
              <div class="d-flex flex-row my-3">
                <button
                  tabindex="3"
                  type="submit"
                  ref="runReportButton"
                  id="runReportBtn"
                  class="btn btn-primary flex-grow-1"
                >
                  <span class="indicator-label"> Run Report </span>

                  <span class="indicator-progress">
                    Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Form>
        <!--end::Form-->
        <!--begin::Card body-->
        <div class="card-body pt-5" v-if="areResultsReady">
          <!--begin:: Flex Container-->
          <div class="d-flex flex-column-reverse flex-sm-row">
            <!--begin::Search-->
            <input
              type="text"
              class="form-control form-control-lg form-control-solid px-5 mb-2"
              name="search"
              v-model="searchSurveys"
              placeholder="Filter by last name"
              data-kt-search-element="input"
            />
            <!--end::Search-->

            <button
              tabindex="3"
              type="submit"
              ref="exportToExcelButton"
              id="exportToExcelBtn"
              class="btn btn-primary mb-2 w-21-sm"
              @click="exportToExcel()"
              v-if="isExcelFileReady === false"
            >
              <span class="indicator-label"> Export To Excel </span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <a
              v-if="isExcelFileReady"
              :href="'/excel/survey-validity-studies/' + excelFileResponse"
              class="btn btn-sm btn-success"
              style="width: 250px; font-size: 16px"
              download
              >Download Excel File
              <img
                style="max-width: 20px"
                src="/media/icons/duotune/files/fil009.svg"
              />
            </a>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Nat A</th>
                  <th scope="col">Nat S</th>
                  <th scope="col">Nat P</th>
                  <th scope="col">Nat C</th>
                  <th scope="col">Nat J</th>
                  <th scope="col">Nat PE</th>
                  <th scope="col">Nat Profile</th>
                  <th scope="col">Nat Profile#</th>
                  <th scope="col">Nat Words Checked</th>
                  <th scope="col">Env A</th>
                  <th scope="col">Env S</th>
                  <th scope="col">Env P</th>
                  <th scope="col">Env C</th>
                  <th scope="col">Env J</th>
                  <th scope="col">Env PE</th>
                  <th scope="col">Env Profile</th>
                  <th scope="col">Env Profile#</th>
                  <th scope="col">Env Words Checked</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in filterSurveys" :key="index">
                  <th scope="row">
                    {{ item.respondentFirstName }} {{ item.respondentLastName }}
                  </th>
                  <td>{{ item.a_NatTraitCount }}</td>
                  <td>{{ item.s_NatTraitCount }}</td>
                  <td>{{ item.p_NatTraitCount }}</td>
                  <td>{{ item.c_NatTraitCount }}</td>
                  <td>{{ item.j_NatTraitCount }}</td>
                  <td>{{ item.pE_NatTraitCount }}</td>
                  <td>{{ item.naturalProfileName }}</td>
                  <td>{{ item.naturalProfileNumber }}</td>
                  <td>
                    <p
                      v-for="(wrd, index2) in item.naturalWordsChecked"
                      :key="index2"
                      class="m-0 float-start"
                    >
                      {{ wrd }},
                    </p>
                  </td>
                  <td>{{ item.a_EnvTraitCount }}</td>
                  <td>{{ item.s_EnvTraitCount }}</td>
                  <td>{{ item.p_EnvTraitCount }}</td>
                  <td>{{ item.c_EnvTraitCount }}</td>
                  <td>{{ item.j_EnvTraitCount }}</td>
                  <td>{{ item.pE_EnvTraitCount }}</td>
                  <td>{{ item.environmentalProfileName }}</td>
                  <td>{{ item.environmentalProfileNumber }}</td>
                  <td>
                    <p
                      v-for="(wrd, index3) in item.environmentalWordsChecked"
                      :key="index3"
                      class="m-0 float-start"
                    >
                      {{ wrd }},
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--end::Flex Container-->
        <!--end::Card body-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  toRefs,
  ref,
  reactive,
  ComputedRef,
  computed,
  watch,
  toRaw,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  ExportSurveyValidityStudyRequestDto,
  ExportToExcelResponseDto,
} from "../../core/types/ExcelTypes";
import * as Yup from "yup";
import {
  SurveyValidityRequestDto,
  SurveyValidityLineItemDto,
} from "../../core/types/AnalysisTypes";

interface DropDownSetup {
  selectedClient: number;
  selectedClientLocation: number;
}

export default defineComponent({
  name: "survey-validity",
  components: { Field, Form, ErrorMessage },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const initialDropDownSetup: DropDownSetup = {
      selectedClient: 0,
      selectedClientLocation: 0,
    };
    const runReportButton = ref<HTMLElement | null>(null);
    const exportToExcelButton = ref<HTMLButtonElement | null>(null);
    const dropDownSetup = reactive<DropDownSetup>({ ...initialDropDownSetup });
    const areResultsReady = ref(false);
    const isExcelFileReady = ref(false);
    const surveyValidityRequestDto = ref<SurveyValidityRequestDto>({
      clientId: 0,
      locationId: 0,
    });
    const searchSurveys = ref("");
    const validateForm = Yup.object().shape({
      clientLocationField: Yup.number()
        .min(10, "Location is required")
        .required()
        .label("Client Location"),
    });

    const exportSurveyValidityStudyRequestDto =
      ref<ExportSurveyValidityStudyRequestDto>({
        clientId: 0,
        locationId: 0,
        surveys: [],
      });

    onMounted(() => {
      setCurrentPageTitle("Survey Analysis");
      setCurrentPageBreadcrumbs("Survey Validity Report", ["Survey Analysis"]);
      getClients();
    });

    const getClients = () => {
      resetResultAreReady();

      store
        .dispatch(Actions.GET_CLIENTS_DDL, 0)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving clients.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const clientList = computed(() => {
      return store.getters.getClientDDL;
    });

    const getClientLocations = () => {
      dropDownSetup.selectedClientLocation = 0;
      store
        .dispatch(
          Actions.GET_CLIENT_LOCATIONS_DDL,
          toRaw(dropDownSetup.selectedClient)
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving client locations.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const clientLocationList = computed(() => {
      return store.getters.getClientLocationsDDL;
    });

    const surveyValidityResults = computed(() => {
      return store.getters.surveyAnalysisValidityResults;
    });

    const onSubmitRunReport = () => {
      // Activate indicator
      runReportButton.value?.setAttribute("data-kt-indicator", "on");

      isExcelFileReady.value = false;

      surveyValidityRequestDto.value.clientId = dropDownSetup.selectedClient;
      surveyValidityRequestDto.value.locationId =
        dropDownSetup.selectedClientLocation;

      store
        .dispatch(Actions.POST_SURVEY_VALIDITY, surveyValidityRequestDto.value)
        .then(() => {
          //
          runReportButton.value?.removeAttribute("data-kt-indicator");
          areResultsReady.value = true;
        })
        .catch(() => {
          runReportButton.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem retrieving survey validity",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    const filterSurveys: ComputedRef<Array<SurveyValidityLineItemDto>> =
      computed(() => {
        return surveyValidityResults.value.filter((survey) => {
          return (
            survey.respondentLastName
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1
          );
        });
      });

    const excelFileResponse: ComputedRef<ExportToExcelResponseDto> = computed(
      () => {
        return store.getters.getExcelFile;
      }
    );

    watch(excelFileResponse, () => {
      if (excelFileResponse.value.excelFilename !== "na") {
        isExcelFileReady.value = true;

        //Deactivate indicator
        exportToExcelButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = false;
      } else if (excelFileResponse.value.excelFilename === "na") {
        isExcelFileReady.value = false;
      }
    });

    function resetResultAreReady() {
      areResultsReady.value = false;
    }

    function exportToExcel() {
      exportSurveyValidityStudyRequestDto.value.clientId =
        dropDownSetup.selectedClient;
      exportSurveyValidityStudyRequestDto.value.locationId =
        dropDownSetup.selectedClientLocation;
      exportSurveyValidityStudyRequestDto.value.surveys =
        surveyValidityResults.value;

      store.commit(Mutations.PURGE_EXCEL_FILE);

      store
        .dispatch(
          Actions.EXPORT_SURVEY_VALIDITY_STUDY_TO_EXCEL,
          exportSurveyValidityStudyRequestDto.value
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem with sync",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (exportToExcelButton.value) {
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = true;
        // Activate indicator
        exportToExcelButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    return {
      currentUser,
      validateForm,
      exportSurveyValidityStudyRequestDto,
      runReportButton,
      exportToExcelButton,
      ...toRefs(dropDownSetup),
      areResultsReady,
      isExcelFileReady,
      surveyValidityRequestDto,
      clientList,
      filterSurveys,
      searchSurveys,
      getClientLocations,
      clientLocationList,
      surveyValidityResults,
      onSubmitRunReport,
      excelFileResponse,
      exportToExcel,
    };
  },
});
</script>
